<template>
  <main role="main">
    <div>
      <div class="main-content">
        <div class="header bg-gradient-info py-7 py-lg-8">
          <div class="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"></svg>
          </div>
        </div>
        <div class="mt--8 pb-5 container">
          <div class="justify-content-center row">
            <div class="card card-body">
              <div class="text-center card-title" style="font-weight: bold">
                ~~ PRIVACY &amp; POLICY ~~
              </div>
              <div class="text-center"></div>
              <div class="col-lg-12 row" style="text-transform: uppercase">
                <div>
                  <p>
                    Welcome to our application. By using our services, you agree
                    to the following terms and conditions:
                  </p>
                  <ul>
                    <li>
                      We respect your privacy and are committed to protecting
                      your personal data.
                    </li>
                    <li>
                      Your data will only be used to improve our services and
                      will not be shared with third parties without your
                      consent.
                    </li>
                    <li>
                      You are responsible for maintaining the confidentiality of
                      your account information.
                    </li>
                    <li>
                      Any misuse of the application will result in immediate
                      termination of your account.
                    </li>
                    <li>
                      We reserve the right to update these terms at any time,
                      and it is your responsibility to review them periodically.
                    </li>
                  </ul>
                  <p>
                    <strong>Privacy Policy:</strong>
                  </p>
                  <ul>
                    <li>
                      We collect only the data necessary to provide and improve
                      our services.
                    </li>
                    <li>
                      Your data is stored securely and is protected against
                      unauthorized access.
                    </li>
                    <li>
                      You have the right to access, modify, or delete your
                      personal data at any time.
                    </li>
                    <li>
                      We do not sell or rent your personal data to third
                      parties.
                    </li>
                    <li>
                      For any concerns regarding your privacy, please contact
                      our support team.
                    </li>
                  </ul>
                  <p>
                    <strong>Additional Information:</strong>
                  </p>
                  <ul>
                    <li>
                      Our application may use cookies to enhance user experience
                      and analyze site traffic.
                    </li>
                    <li>
                      We may update our privacy policy periodically, and it is
                      your responsibility to stay informed about these changes.
                    </li>
                    <li>
                      By continuing to use our services, you agree to the terms
                      outlined in our privacy policy.
                    </li>
                    <li>
                      If you have any questions or require further
                      clarification, feel free to reach out to us.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "TermsAndConditions",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToUrl() {},
  },
};
</script>
